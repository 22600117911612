<template>
  <div class="CompoundQuestionType" style="margin: -15px -15px">
    <div class="Previous_view_right">
      <div class="Previous_view_right_top">
        <div style="border-bottom: 15px solid #F0F2F5;">
          <div class="top_cont">
            <div class="EduRec-top-input">
              <div class="StemName">题干名称：</div>
              <a-input @pressEnter="selectName" allowClear style="width: 300px" v-model="StemName" placeholder="请输入"/>

              <a-button @click="selectName" class="btn" type="primary">查询</a-button>
            </div>
          </div>
        </div>
        <div class="activity-table">
          <a-button @click="AddModel" style="margin-bottom: 20px" class="btn" type="primary">新增</a-button>
          <!--           :rowKey="record=>record.id"-->
          <a-table
              :rowKey="record=>record.id"
              :pagination="false"
              :columns="columns"
              :data-source="data">
          <span slot="questionSum" slot-scope="text, record">
            <a @click="openDetail(record)">{{record.questionSum}}</a>
          </span>
          <span slot="system" slot-scope="text, record">
            <a @click="amendModel(record)" style="margin-right: 8px">修改</a>

            <a-popconfirm
                v-if="record.questionSum === 0"
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="confirm(record)"
            ><a>删除</a></a-popconfirm>

            <span v-else style="color: #dcdcdc">删除</span>
          </span>
          </a-table>
          <!--分页-->
          <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          :page-size.sync="pagination.pageSize"
                          show-size-changer show-quick-jumper
                          @showSizeChange="showSizeChange"
                          @change="pageChange" />
          </div>
        </div>
      </div>

    </div>
<!----------------------------对话框-------------------------------->
    <a-modal v-model="visible" :title="visibleTitle" @ok="handleOk" @cancel="handleCancel">
      <a-textarea
          v-model="textareaVal"
          placeholder="请输入题干名称"
          :auto-size="{ minRows: 5, maxRows: 10 }"
      />
    </a-modal>
    <a-drawer
        width="900"
        title="关联题目详情"
        placement="right"
        :visible="visibleDrawer"
        @close="onClose"
    >
      <div class="drawerView">
        <div class="drawerView_top">
          <div class="drawerView_top_title">题干名称</div>
          <div style="margin-top: 20px;margin-bottom: 20px">{{drawerTitle}}</div>
        </div>
        <div class="drawerView_top">
          <div class="drawerView_top_title" style="margin-top: 30px;margin-bottom: 20px">关联题目</div>
          <div class="activity-table">
            <!--           :rowKey="record=>record.id"-->
            <a-table
                :rowKey="record=>record.id"
                :pagination="false"
                :columns="columnsDrawer"
                :data-source="dataDrawer">
            </a-table>
            <!--分页-->
            <div class="pageView">
              <span class="pageLeft">共 {{ paginationDrawer.total }} 条记录 第 {{
                  paginationDrawer.current
                }} / {{ Math.ceil(paginationDrawer.total / paginationDrawer.pageSize) }} 页</span>
              <a-pagination v-model="paginationDrawer.current"
                            :total="paginationDrawer.total"
                            :page-size-options="pageSizeOptions"
                            :page-size.sync="paginationDrawer.pageSize"
                            show-size-changer show-quick-jumper
                            @showSizeChange="showSizeChangeDrawer"
                            @change="pageChangeDrawer" />
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>

import {
  delQuestionSelectAll, getQuestionSelect,
  getQuestionSelectAll, postQuestionSelectAll, putQuestionSelectAll,
} from "@/service/titleExam_api";

const columns = [
  {
    title: '题干名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: "30%",
    ellipsis: true,
  },{
    title: '关联题目数量',
    dataIndex: 'questionSum',
    key: 'questionSum',
    align: 'center',
    ellipsis: true,
    width:'10%',
    scopedSlots: { customRender: 'questionSum' },
  },
  {
    title: '创建人',
    dataIndex: 'createdBy',
    key: 'createdBy',
    align: 'center',
    width:'8%'
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    align: 'center',
    ellipsis: true,
    width:'8%'
  },
  {
    title: '操作',
    dataIndex: 'system',
    key: 'system',
    align: 'center',
    width: '10%',
    scopedSlots: { customRender: 'system' },
  },
];
let data = [];


const columnsDrawer = [
  {
    title: '题目名称',
    dataIndex: 'questionName',
    key: 'questionName',
    align: 'center',
    width: "30%",
    ellipsis: true,
  },{
    title: '所属分类',
    dataIndex: 'questionTypeName',
    key: 'questionTypeName',
    align: 'center',
    ellipsis: true,
    width:'20%'
  },
  {
    title: '所属模块',
    dataIndex: 'typeName',
    key: 'typeName',
    align: 'center',
    width:'15%',
    ellipsis: true,
  },
];
let dataDrawer = [];
export default {
  name: "CompoundQuestionType",
  data(){
    return{
      pagination:{
        total:0,
        pageSize: 10,
        current:1,
      },
      paginationDrawer:{
        total:0,
        pageSize: 10,
        current:1,
      },
      spinning:false,
      data,
      columns,

      dataDrawer,
      columnsDrawer,

      StemName:'',

      visible:false,
      visibleDrawer:false,
      visibleTitle:'新增',

      textareaVal:'',

      listId:null,

      drawerTitle:'',

      pageSizeOptions: ['1', '10', '20', '30', '40', '50'],

      detail:null,
    }
  },
  created() {
    this.questionSelectAll();
  },
  methods:{
    //获取列表
    async questionSelectAll() {
      let data = {
        name:this.StemName,
        page:this.pagination.current,
        pageSum:this.pagination.pageSize
      }
      const res = await getQuestionSelectAll(data);
      if (res.statuscode === 200) {
        this.data = res.data;
        this.pagination.total = res.count;
      }
    },
    //删除
    async delList(id) {
      const res = await delQuestionSelectAll(id);
      if (res.statuscode === 200) {
        this.questionSelectAll();
      }else {
        this.$message.warning(res.message);
      }
    },
    //新增
    async addList(data) {
      const res = await postQuestionSelectAll(data);
      if (res.statuscode === 200) {
        this.questionSelectAll();
        this.visible = false;
      }else {
        this.$message.warning(res.message);
      }
    },
    //修改
    async putList(data) {
      const res = await putQuestionSelectAll(data);
      if (res.statuscode === 200) {
        this.questionSelectAll();
        this.visible = false;
      }else {
        this.$message.warning(res.message);
      }
    },
    selectName(){
      this.pagination.current = 1;
      this.questionSelectAll();
    },
    showSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.questionSelectAll();
    },
    pageChange(pageNum,pageSize){
      this.pagination.current = pageNum;
      this.pagination.pageSize = pageSize;
      this.questionSelectAll();
    },


    showSizeChangeDrawer(current, pageSize) {
      this.paginationDrawer.current = current;
      this.paginationDrawer.pageSize = pageSize;
      this.questionDrawer();
    },
    pageChangeDrawer(pageNum,pageSize){
      this.paginationDrawer.current = pageNum;
      this.paginationDrawer.pageSize = pageSize;
      this.questionDrawer();
    },
    AddModel(){
      this.visibleTitle = "新增";
      this.textareaVal = '';
      this.visible = true;
    },
    amendModel(row){
      this.visibleTitle = '修改';
      this.listId = row.id;
      this.textareaVal = row.name;
      this.visible = true;
    },
    handleOk(){
      if(this.textareaVal){
        if(this.visibleTitle === '新增'){
          let addData ={
            "name": this.textareaVal//题干名称
          }
          this.addList(addData);
        }else if(this.visibleTitle === '修改'){
          let restData = {
            "id": this.listId,//id
            "name": this.textareaVal//名称
          }
          this.putList(restData);
        }
      }else {
        this.$message.warning("请输入题干名称！")
      }
    },
    handleCancel(){
      this.textareaVal = '';
    },
    onClose(){
      this.visibleDrawer = false;
      this.paginationDrawer.current = 1;
      this.paginationDrawer.pageSize = 10;
      this.drawerTitle = '';
    },
    confirm(row){
      this.delList(row.id)
    },
    openDetail(row){
      this.detail = row.id;
      this.drawerTitle = row.name;

      this.questionDrawer(); //获取对话框中的表格列表

      this.visibleDrawer = true;

    },
    async questionDrawer() {
      let data = {
        page:this.paginationDrawer.current,
        pageSum:this.paginationDrawer.pageSize
      }
      const res = await getQuestionSelect(this.detail,data);
      if (res.statuscode === 200) {
        this.dataDrawer = res.data;
        this.paginationDrawer.total = res.count;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.ant-menu{
  border: none;
}
.CompoundQuestionType{
  //min-height: 110%;
  display: flex;
  justify-content: space-between;
  .Previous_view_right{
    width: 100%;
    .Previous_view_right_top{
    }
    .top_cont{
      width: 98%;
      margin: auto;
      height: 80px;
      padding-top: 10px;
    }
    .EduRec-top-input{
      display: flex;
      height: 50px;
      padding-top: 15px;
      .StemName{
        //width: 120px;
        margin-top: 5px;
      }
      .btn{
        margin-left: 20px;
      }
    }

    .activity-table{
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }
    .pageView{
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .pageLeft{
      font-size: 15px;color: #929292
    }
  }
}
.drawerView{
  width: 100%;
  .drawerView_top{
    width: 98%;
    margin: auto;
    .drawerView_top_title{
      color: #000000;
      font-weight: bold;
    }
  }
  .pageView{
    display: flex;
    justify-content: space-between;
    z-index: 3;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .pageLeft{
    font-size: 15px;color: #929292
  }
}
</style>
